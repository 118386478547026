// import React from "react";
// import Navbar from "../../Components/Navbar";
// import Footer from "../../Components/Footer";
// import Button from "../../Components/Button";
// import img4 from "../../image/beer (37).jpg";
// import img2 from "../../image/beer (37).jpg";
// import img3 from "../../image/beer (37).jpg";
// import imgbody1 from "../../image/beer (35).jpg";
// import imgbody2 from "../../image/beer (43) (1).jpg";
// import QuestionComponent from "../../Components/Question";

// import { Link } from 'react-router-dom';

// function Restaurant() {
//   return (
//     <div>
//       <div className="bg-black text-white ">
//         <Navbar />
//       </div>
    
//       <div className="bg-gray-100 h-[550px] max-sm:h-[300px]">
//         <div className="h-[550px] max-sm:h-[300px]">
//           <QuestionComponent textquestion="Do you want to sell our beer?" />
//           <div className="flex justify-center overflow-hidden mt-[20px] ">
//             <img
//               className="w-[250px] h-[330px] object-cover bg-orange-400 max-sm:w-[100px] max-sm:h-[150px] flex"
//               src={img2}
//               alt="Beer 1"
//             />
//             <img
//               className="w-[250px] h-[330px] object-cover bg-amber-900 mx-[10px] max-sm:w-[100px] max-sm:h-[150px] flex"
//               src={img3}
//               alt="Beer 2"
//             />
//             <img
//               className="w-[250px] h-[330px] object-cover bg-teal-900 max-sm:w-[100px] max-sm:h-[150px]   flex"
//               src={img4}
//               alt="Beer 3"
//             />
//           </div>
//         </div>
//       </div>

//       <div className="space-y-10 max-sm:w-[80%] max-sm:flex-col justify-center mx-auto">
//         <div className=" bg-white flex justify-center max-sm:flex-col space-x-10 max-sm:space-x-0">
//           <ul className="list-disc pl-5 text-[20px] font-abc leading-[50px] max-sm:flex-col max-sm:text-[12px] max-sm:leading-6 mt-[50px]">
//             <h2 className="font-bold font-abc"> Draft</h2>
//             <li>
//               Installation , maintenance and regular cleaning of the draft
//               machine
//             </li>
//             <li>Free delivery of kegs </li>
//             <li>
//               On-sight training for bar personal and service staff on how to use
//               <br />
//               the system and about the product itself
//             </li>
//             <li>Provision of glassware and coasters </li>
//           </ul>

//           <div className="mt-[50px]">
//             <img
//               src={imgbody1}
//               alt=""
//               className="w-[600px] max-sm:h-[200px] object-cover"
//             />
//           </div>
//         </div>

//         <div className=" bg-white flex max-sm:flex-col  max-sm:space-x-0 justify-center space-x-10">
//           <div>
//             <img
//               src={imgbody2}
//               alt=""
//               className="w-[600px] max-sm:h-[200px]  object-cover"
//             />
//           </div>

//           <ul className="list-disc pl-5 text-[20px] max-sm:flex-col font-abc leading-[50px] max-sm:text-[12px] max-sm:leading-6">
//             <h2 className="text-[20px] max-sm:text-[12px] font-bold font-abc  ">
//             Bottle            </h2>
//             <li>
//               Better solution for smaller businesses (little space) and/or less
//               <br />
//               expected consumption
//             </li>
//             <li>Lower MOQ (1 box – 24 bottles; mixed cases possible)</li>
//             <li>Free delivery</li>
//             <li>
//               On-site training for bar personnel and service staff on how to use
//               <br />
//               the system and about the product itself
//             </li>
//             <li>Provision of glassware and coasters</li>
//           </ul>
//         </div>
//       </div>
      
//       <div className="w-[50%] max-sm:w-[80%] mx-auto flex h-[200px] max-sm:h-[80px] items-center">
//       <p className="text-[20px] font-abc text-center max-sm:text-[12px]">
//         {" "}
//         Are you interested in selling real Cambodian craft beer at your F&B
//         business? We provide draft beer as well as bottles. Contact us{" "}
//         <Link className="text-red-600" to="/contact">
//           here
//         </Link>
//       </p>
//       </div>
//       <div className="bg-gray-100 flex items-center justify-center h-[100px]">
//       <Link to="/contact">
//         <Button name="Contact Us" color="Button-black" />
//       </Link>
//     </div>

//       <Footer />
//     </div>
//   );
// }
// export default Restaurant;

import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Button from "../../Components/Button";
import { Link } from "react-router-dom";
import img4 from "../../image/beer (37).jpg";
import img2 from "../../image/beer (37).jpg";
import img3 from "../../image/beer (37).jpg";
import imgbody1 from "../../image/beer (35).jpg";
import imgbody2 from "../../image/beer (43) (1).jpg";
import QuestionComponent from "../../Components/Question";
import { useTranslation } from "react-i18next";

function Restaurant() {
const { t } = useTranslation();

  return (
    <div>
      <div className="bg-black text-white ">
        <Navbar />
      </div>
    
      <div className="bg-gray-100 h-[550px] max-sm:h-[300px]">
        <div className="h-[550px] max-sm:h-[300px]">
          <QuestionComponent textquestion={t("restaurant-t")} />
          <div className="flex justify-center overflow-hidden mt-[20px] ">
            <img
              className="w-[250px] h-[330px] object-cover bg-orange-400 max-sm:w-[100px] max-sm:h-[150px] flex"
              src={img2}
              alt="Beer 1"
            />
            <img
              className="w-[250px] h-[330px] object-cover bg-amber-900 mx-[10px] max-sm:w-[100px] max-sm:h-[150px] flex"
              src={img3}
              alt="Beer 2"
            />
            <img
              className="w-[250px] h-[330px] object-cover bg-teal-900 max-sm:w-[100px] max-sm:h-[150px]   flex"
              src={img4}
              alt="Beer 3"
            />
          </div>
        </div>
      </div>

      <div className="space-y-10 max-sm:w-[80%] max-sm:flex-col justify-center mx-auto">
        <div className=" bg-white flex justify-center max-sm:flex-col space-x-10 max-sm:space-x-0">
          <ul className="list-disc pl-5 text-[20px] font-abc leading-[50px] max-sm:flex-col max-sm:text-[10px] max-sm:leading-6 mt-[50px]">
            <h2 className="font-bold font-abc"> {t("restaurant-p")}</h2>
            <li>
            {t("restaurant-p1")}
            </li>
            <li>{t("restaurant-p2")} </li>
            <li>
            {t("restaurant-p3")}
              <br />
              <li>{t("restaurant-p4")} </li>
            </li>
            {t("restaurant-p5")} 
          </ul>

          <div className="mt-[50px]">
            <img
              src={imgbody1}
              alt=""
              className="w-[600px] max-sm:h-[200px] object-cover"
            />
          </div>
        </div>

        <div className=" bg-white flex max-sm:flex-col  max-sm:space-x-0 justify-center space-x-10">
          <div>
            <img
              src={imgbody2}
              alt=""
              className="w-[600px] max-sm:h-[200px]  object-cover"
            />
          </div>

          <ul className="list-disc pl-5 text-[20px] max-sm:flex-col font-abc leading-[50px] max-sm:text-[10px] max-sm:leading-6">
            <h2 className="text-[20px] max-sm:text-[10px] font-bold font-abc  ">
             {t("restaurant-p6")}
            </h2>
            <li> {t("restaurant-p7")} <br /> </li>
            <li>{t("restaurant-p8")}</li>
            <li>{t("restaurant-p9")}</li>
            <li>
            {t("restaurant-p10")}
              <br />
              {t("restaurant-p11")}
            </li>
            <li>{t("restaurant-p12")}
              <br />
              {t("restaurant-p13")}
            </li>
          </ul>
        </div>
      </div>
      
      <div className="w-[50%] max-sm:w-[80%] mx-auto flex h-[200px] max-sm:h-[80px] items-center">
      <p className="text-[20px] font-abc text-center max-sm:text-[10px]">
        {" "}
        {t("restaurant-p14")}
        {t("restaurant-p15")}{" "}
        <span className="text-red-600">{t("restaurant-p16")}</span>
      </p>
      </div>

      <Link to="/contact">
      <div className="bg-gray-100  flex items-center justify-center h-[100px] ">
        <Button name={t("restaurant-p17")} color="Button-black" />
      </div>
      </Link>

      <Footer />
    </div>
  );
}
export default Restaurant;